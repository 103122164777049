<template>
  <div>
    <div class="fd-img-wrap">
      <img style="width:100%" src="../assets/images/join.png" alt="">
    </div>
    <div class="fd-zp-content-wrap">
      <div class="fd-heander-tab-wrap">
        <div class="fd-item" :class="{'fd-checked': keyIndex === -1}" @click="clickTab(-1)">华北区<br>总经理</div>
        <div class="fd-item" :class="{'fd-checked': keyIndex === 0}" @click="clickTab(0)">Web前端<br>开发工程师</div>
        <div class="fd-item" :class="{'fd-checked': keyIndex === 1}" @click="clickTab(1)">JAVA高级<br>工程师</div>
        <div class="fd-item" :class="{'fd-checked': keyIndex === 2}" @click="clickTab(2)">客户经理</div>
        <div class="fd-item" :class="{'fd-checked': keyIndex === 3}" @click="clickTab(3)">区域客户<br>经理</div>
        <div class="fd-item" :class="{'fd-checked': keyIndex === 4}" @click="clickTab(4)">区域销售<br>经理</div>
        <div class="fd-item" :class="{'fd-checked': keyIndex === 5}" @click="clickTab(5)">总监助理</div>
        <div class="fd-item" :class="{'fd-checked': keyIndex === 6}" @click="clickTab(6)">UI设计师</div>
      </div>
      <div class="fd-content-yq-wrap">
        <div class="fd-left-wrap">
          <img style="margin-bottom:30px" src="../assets/images/jl.png" alt="">
          <div style="font-size:16px;color:#666;text-align:left;line-height:30px">
            <p>地址: 上海市浦东新区银城路88号中国人寿金融中心7F</p>
            <!-- <p style="text-indent: 45px">深圳市罗湖区深南东路5002号地王大厦55F</p> -->
            <p>邮编: 200120</p>
            <p>电话: 021-50650585</p>
            <p>Web: www.alldobetter.com</p>
            <p>E-mail：shufen.wu@alldobetter.com</p>
          </div>
        </div>
        <div class="fd-right-wrap">
          <div class="fd-gwzz-div">岗位职责</div>
          <!-- 华北区总经理 -->
          <div class="fd-content-type" v-if="keyIndex === -1">
            1、管理与大客户（证券公司、银行等金融机构）的日常沟通，发展和维护与大客户的良好业务关系；<br>
            2、深度挖掘重点客户的潜在需求，协调利用内部顾问、技术资源为客户提供满意的解决方案；<br>
            3、对潜在客户进行定期跟踪，定期提交销售进度报告，完成大客户销售目标；<br>
            4、配合销售团队，完成销售目标；
          </div>
          <!-- web前端开发工程师 -->
          <div class="fd-content-type" v-if="keyIndex === 0">
            1、依据产品需求以及ui设计稿完成高质量的Web前端开发和维护；<br> 
            2、独立完成功能模块的分拆、设计与开发，保证过程的合理和有效；<br>
            3、优化Web前端应用，改善用户体验，丰富Web交互；<br>
          </div>
          <!-- JAVA高级工程师 -->
          <div class="fd-content-type" v-if="keyIndex === 1">
            1.负责根据产品和客户需求，对软件实现的框架以及功能模块进行设计;<br>
            2.负责搭建软件框架、实现软件功能；<br>
            3.负责系统核心功能模块及业务逻辑的代码实现，编写各类技术手册；<br>
            4.负责相关新技术的研究。
          </div>
          <!-- 客户经理 -->
          <div class="fd-content-type" v-if="keyIndex === 2">
            1、管理与大客户（证券公司、银行等金融机构）的日常沟通，发展和维护与大客户的良好业务关系；<br>
            2、深度挖掘重点客户的潜在需求，协调利用内部顾问、技术资源为客户提供满意的解决方案；<br>
            3、对潜在客户进行定期跟踪，定期提交销售进度报告，完成大客户销售目标；<br>
            4、配合销售团队，完成销售目标；<br>
            5、响应客户要求，接受客户投诉，为客户解决问题。
          </div>
          <!-- 区域客户经理 -->
          <div class="fd-content-type" v-if="keyIndex === 3">
            1、管理与客户（证券公司、银行等金融机构）的日常沟通，发展和维护与客户的良好业务关系；<br>
            2、深度挖掘重点客户的潜在需求，协调利用内部顾问、技术资源为客户提供满意的解决方案；<br>
            3、对潜在客户进行定期跟踪，定期提交销售进度报告，完成客户销售目标；<br>
            4、配合销售团队，完成销售目标；<br>
            5、响应客户要求，接受客户投诉，为客户解决问题。
          </div>
          <!-- 区域销售经理 -->
          <div class="fd-content-type" v-if="keyIndex === 4">
            1、管理与大客户（证券公司、银行等金融机构）的日常沟通，发展和维护与大客户的良好业务关系；<br>
            2、深度挖掘重点客户的潜在需求，协调利用内部顾问、技术资源为客户提供满意的解决方案；<br>
            3、对潜在客户进行定期跟踪，定期提交销售进度报告，完成大客户销售目标；<br>
            4、配合销售团队，完成销售目标；<br>
            5、响应客户要求，接受客户投诉，为客户解决问题。
          </div>
          <!-- 总监助理 -->
          <div class="fd-content-type" v-if="keyIndex === 5">
            1、及时准确的完成指派的各项策划等工作，全力支持销售部门的销售工作;<br>
            2、负责各类文案的撰写与任务分配，并进行跟进、总结与分析；<br>
            3、根据需要负责客户需求、挖掘、客户拜访及投标等市场工作；<br>
            4、跟踪公司各类项目，建立相应的资料库；<br>
            5、负责产品开发、致力于行业研究、知识的沉淀；为其它部门带给各项专业知识培训。
          </div>
          <!-- UI设计师 -->
          <div class="fd-content-type" v-if="keyIndex === 6">
            1.负责产品的用户体验及界面设计工作；<br>
            2.与销售部门、开发部门紧密合作，参与产品规划构思和创意过程，充分应用设计经验，<br>结合用户研究和产品数据分析的成果，持续提高界面的用户体验；<br> 
            3.迅速发现界面的优势或弱点，并尽一切可能解决布局，架构，流程，复制或演示问题；<br> 
            4.进行前瞻性的产品界面视觉设计研究、设计流行趋势分析，参与视觉设计规范的建立和维护。
          </div>
          <div class="fd-jnyq-div">技能要求</div>
          <!-- 华北区总经理 -->
          <div class="fd-content-type" v-if="keyIndex === -1">
            1、本科以上学历，形象气质佳；<br>
            2、3年以上针对证券公司、银行等金融机构销售工作经验；<br>
            3、具有深厚的行业背景和一定的金融机构客户资源，有出色的销售业绩；<br>
            4、具有较强的公关、谈判、与高层人士沟通的能力，较强的分析问题和解决问题能力；<br>
            5、自学能力强，责任心强、有敬业精神，能承受较大的工作压力。
          </div>
          <!-- web前端开发工程师 -->
          <div class="fd-content-type" v-if="keyIndex === 0">
            1、熟练使用HTML5、CSS3、Javascript，对Web标准和标签语义化及模块化有一定理解。<br>
            2、能熟练使用React.JS进行SPA开发，了解Axios，能够与后端人员无缝对接开发。<br>
            3、熟悉图表组件的运用，如Echarts。<br>
            4、熟悉开发构建工具，如：webpack。<br>
            5、 熟悉主流版本管理软件（Git／SVN…）。
          </div>
          <!-- JAVA高级工程师 -->
          <div class="fd-content-type" v-if="keyIndex === 1">
            1.负责根据产品和客户需求，对软件实现的框架以及功能模块进行设计;<br>
            2.负责搭建软件框架、实现软件功能；<br>
            3.负责系统核心功能模块及业务逻辑的代码实现，编写各类技术手册；<br>
            4.负责相关新技术的研究。
          </div>
          <!-- 客户经理 -->
          <div class="fd-content-type" v-if="keyIndex === 2">
            1、本科以上学历，形象气质佳；<br>
            2、2年以上针对证券公司、银行等金融机构销售工作经验；<br>
            3、具有深厚的行业背景和一定的金融机构客户资源，有出色的销售业绩；<br>
            4、具有较强的公关、谈判、与高层人士沟通的能力，较强的分析问题和解决问题能力；<br>
            5、自学能力强，责任心强、有敬业精神，能承受较大的工作压力。
          </div>
          <!-- 区域客户经理 -->
          <div class="fd-content-type" v-if="keyIndex === 3">
            1、本科以上学历，形象气质佳；<br/>
            2、一年以上针对证券公司、银行等金融机构销售工作经验；<br>
            3、具有深厚的行业背景和一定的金融机构客户资源，有出色的销售业绩；<br>
            4、具有较强的公关、谈判、与高层人士沟通的能力，较强的分析问题和解决问题能力；<br>
            5、自学能力强，责任心强、有敬业精神，能承受较大的工作压力。
          </div>
          <!-- 区域销售经理 -->
          <div class="fd-content-type" v-if="keyIndex === 4">
            1、本科以上学历，形象气质佳；<br>
            2、2年以上针对证券公司、银行等金融机构销售工作经验；<br>
            3、具有深厚的行业背景和一定的金融机构客户资源，有出色的销售业绩；<br>
            4、具有较强的公关、谈判、与高层人士沟通的能力，较强的分析问题和解决问题能力；<br>
            5、自学能力强，责任心强、有敬业精神，能承受较大的工作压力。
          </div>
          <!-- 总监助理 -->
          <div class="fd-content-type" v-if="keyIndex === 5">
            1、 美术设计类、广告学及相关专业，本科及以上学历；<br>
            2、 有三年以上从事设计经验，具有较好的色彩、造型感觉；<br>
            3、 较强的设计管理能力和沟通能力，熟练掌握平面设计的各种技能，对设计有一定的创意及深度的理解；<br>
            4、 对文案及策略有较强理解力，作品富有创意，色彩把握及整体构成有良好运用能力；<br>
            5、 具有一定的组织能力和团队合作精神；<br>
            6、 具有优秀的沟通技巧和人际交往能力。
          </div>
          <!-- UI设计师 -->
          <div class="fd-content-type" v-if="keyIndex === 6">
            1.有2年以上移动互联网产品界面、HTML5页面、图标的设计经验;<br>
            2.对UI及交互设计相关概念、工作流程有深刻的理解；<br> 
            3.有丰富的设计理论知识和对流行趋势敏锐的洞察力，对设计趋势有灵敏嗅觉和领悟能力，能帮助提升团队的设计能力；<br> 
            4.熟练使用Photoshop，Illustrator，Sketch等设计软件；了解动效设计。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setInterval, setTimeout, clearInterval } from 'timers';
export default {
  name: 'cpyc',
  data () {
    return {
      keyIndex: -1,
      timer: null
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods:{
    // 循环调用轮播
    loop () {
      let _this =this
      _this.timer = setInterval(function(){
        _this.keyIndex += 1
        if (_this.keyIndex === 7) {
          _this.keyIndex = 0
        }
      }, 3000)
    },
    //上面菜单的点击事件
    clickTab (index) {
      let _this = this
      // clearInterval(_this.timer)
      // _this.timer = null
      _this.keyIndex = index
      // setTimeout(() => {
      //   this.loop()
      // },3000)
    }
  },
  mounted () {
    // this.loop()
  }
}
</script>

<style lang="stylus" scoped>
.fd-content-yq-wrap
  margin-top 31px
  display flex
  justify-content space-between
  // align-items baseline
  padding 29px 32px
  background-color rgba(255,255,255,1)
  box-shadow 0px 0px 4px 0px rgba(130,130,130,0.24)
  .fd-left-wrap
    width 488px
  .fd-right-wrap
    width 623px
    .fd-gwzz-div, .fd-jnyq-div
      width 127px
      height 40px
      text-align center
      line-height 40px
      background-color rgba(2,107,167,1)
      color white
      font-size 18px
      margin-bottom 21px
    .fd-content-type
      font-size 16px
      line-height 30px
      color #666
      margin-bottom 20px
.fd-checked
  color white !important 
  background-color rgba(2,107,167,1) !important
.fd-zp-content-wrap
  width 1200px
  margin 0 auto 
  padding 80px 0
  .fd-heander-tab-wrap
    width 100%
    display flex
    align-items center
    justify-content space-between
    // position relative
    .fd-item
      width 130px
      height  80px
      cursor pointer
      color rgba(2,107,167,1)
      border 1px solid rgba(2,107,167,1)
      background-color rgba(255,255,255,1)
      font-size 18px
      line-height 21px
      padding 18px 15px
      text-align center
      display flex
      flex-wrap wrap
      justify-content center
      align-items center
      align-content center
</style>

